import './scss/main.scss'

import $ from 'jquery';

import 'slick-carousel'

import "bootstrap";

$('.slides-home').slick({
    dots: false,
    autoplay: false,
    arrows: true,
    slidesToShow: 2,
    slidesToScroll: 1,
    responsive: [
        {
            breakpoint: 992,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1,
                arrows: false,
            }
        }
    ]
});

$(".button-menu").click(function(){
	$(".menu-mobile").addClass("active");
});

$(".button-menu-close").click(function(){
	$(".menu-mobile").removeClass("active");
});

$(".menu-mobile li a").click(function(){
	$(".menu-mobile").removeClass("active");
});